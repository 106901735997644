.footer{
  display: flex;
  justify-content: space-between;
  padding: 100px 70px;
  flex-wrap: wrap;
  &__logo{
    margin-bottom: 30px;
    width: 150px;
    img{
      width: 100%;
    }
  }
  &__socialIcon{
    display: flex;
    align-items: center;
    gap: 30px;
  }
  &__navigation{
    align-items: flex-end;
    display: flex;
    gap: 60px;
    &_block{
      display: flex;
      flex-direction: column;
      gap: 35px;
      a{
        display: block;
        color: #1084FF;
      }
    }
  }
}

@media (max-width: 1280px) {
  .footer{
    padding: 100px 30px;
  }
}

@media (max-width: 1024px) {
  .footer{
    &__navigation:last-child{
      width: 100%;
      margin-top: 120px;
    }
  }
}

@media (max-width: 570px) {
  .footer{
    padding: 15px;
    &__navigation{
      width: 100%;
      margin-top: 30px !important;
    }
    &__navigation:last-child{
      flex-direction: column;
      align-items: flex-start !important;
    }
  }
}
