@font-face {
    font-family: 'Mont';
    /*src: url('/font/Mont/Mont-Black.eot');*/
    src: local('Mont Black'), local('/font/Mont/Mont-Black'),
    /*url('/font/Mont/Mont-Black.eot?#iefix') format('embedded-opentype'),*/
    /*url('/font/Mont/Mont-Black.woff2') format('woff2'),*/
    /*url('/font/Mont/Mont-Black.woff') format('woff'),*/
    url('/font/Mont/Mont-Black.ttf') format('truetype');
    font-weight: 900;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    /*src: url('/font/Mont/Mont-ExtraLight.eot');*/
    src: local('Mont ExtraLight'), local('/font/Mont/Mont-ExtraLight'),
    /*url('/font/Mont/Mont-ExtraLight.eot?#iefix') format('embedded-opentype'),*/
    /*url('/font/Mont/Mont-ExtraLight.woff2') format('woff2'),*/
    /*url('/font/Mont/Mont-ExtraLight.woff') format('woff'),*/
    url('/font/Mont/Mont-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    /*src: url('/font/Mont/Mont-Bold.eot');*/
    src: local('Mont Bold'), local('/font/Mont/Mont-Bold'),
    /*url('/font/Mont/Mont-Bold.eot?#iefix') format('embedded-opentype'),*/
    /*url('/font/Mont/Mont-Bold.woff2') format('woff2'),*/
    /*url('/font/Mont/Mont-Bold.woff') format('woff'),*/
    url('/font/Mont/Mont-Bold.ttf') format('truetype');
    font-weight: bold;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Mont';
    /*src: url('/font/Mont/Mont-Heavy.eot');*/
    src: local('Mont Heavy'), local('/font/Mont/Mont-Heavy'),
    /*url('/font/Mont/Mont-Heavy.eot?#iefix') format('embedded-opentype'),*/
    /*url('/font/Mont/Mont-Heavy.woff2') format('woff2'),*/
    /*url('/font/Mont/Mont-Heavy.woff') format('woff'),*/
    url('/font/Mont/Mont-Heavy.ttf') format('truetype');
    font-weight: 800;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    /*src: url('/font/Mont/Mont-SemiBold.eot');*/
    src: local('Mont SemiBold'), local('/font/Mont/Mont-SemiBold'),
    /*url('/font/Mont/Mont-SemiBold.eot?#iefix') format('embedded-opentype'),*/
    /*url('/font/Mont/Mont-SemiBold.woff2') format('woff2'),*/
    /*url('/font/Mont/Mont-SemiBold.woff') format('woff'),*/
    url('/font/Mont/Mont-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Mont';
    /*src: url('/font/Mont/Mont-Thin.eot');*/
    src: local('Mont Thin'), local('/font/Mont/Mont-Thin'),
    /*url('/font/Mont/Mont-Thin.eot?#iefix') format('embedded-opentype'),*/
    /*url('/font/Mont/Mont-Thin.woff2') format('woff2'),*/
    /*url('/font/Mont/Mont-Thin.woff') format('woff'),*/
    url('/font/Mont/Mont-Thin.ttf') format('truetype');
    font-weight: 100;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    /*src: url('/font/Mont/Mont-Regular.eot');*/
    src: local('Mont Regular'), local('/font/Mont/Mont-Regular'),
    /*url('/font/Mont/Mont-Regular.eot?#iefix') format('embedded-opentype'),*/
    /*url('/font/Mont/Mont-Regular.woff2') format('woff2'),*/
    /*url('/font/Mont/Mont-Regular.woff') format('woff'),*/
    url('/font/Mont/Mont-Regular.ttf') format('truetype');
    font-weight: normal;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    /*src: url('/font/Mont/Mont-Light.eot');*/
    src: local('Mont Light'), local('/font/Mont/Mont-Light'),
    /*url('/font/Mont/Mont-Light.eot?#iefix') format('embedded-opentype'),*/
    /*url('/font/Mont/Mont-Light.woff2') format('woff2'),*/
    /*url('/font/Mont/Mont-Light.woff') format('woff'),*/
    url('/font/Mont/Mont-Light.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}

